import React, { useEffect, useState } from "react";

import { ds } from "js/core/models/dataService";
import { app } from "js/namespaces";
import { getCanvasBundle } from "legacy-js/canvas";
import appController from "legacy-js/core/AppController";
import { SharedThemes } from "legacy-js/core/models/sharedTheme";
import { BuiltInThemes, UserThemes } from "legacy-js/core/models/theme";
import UndoManager from "legacy-js/core/services/undoManager";
import { ThemeManager } from "legacy-js/core/themeManager";
import { AuthConsumer } from "legacy-js/react/views/Auth/AuthConsumer";
import { FirebaseAuthProvider } from "legacy-js/react/views/Auth/FirebaseAuthContext";

import getLogger, { LogGroup } from "js/core/logger";

const logger = getLogger(LogGroup.PPT_ADDIN);

function PPTAddinAppInitializer({ children }) {
    const [isReady, setReady] = useState(false);

    useEffect(() => {
        logger.info("[PPTAddin][PPTAddinAppInitializer] mounted");

        async function fetchData() {
            // This is an ugly hack that allows presentation model to pull the correct
            // implementations
            ds.prepare({ SharedThemes, BuiltInThemes, UserThemes });
            app.themeManager = new ThemeManager();
            app.getCanvasBundle = getCanvasBundle;
            app.undoManager = new UndoManager();
            app.appController = appController;
            window.appLoaderController = {
                forceLegacyApp: () => { },
                forceLatestApp: () => { },
                resetAppState: () => { }
            };

            const { default: Adapter } = await import(/* webpackMode: "eager" */ "js/core/storage/adapter");
            const { default: FirebaseAdapter } = await import(/* webpackMode: "eager" */ "js/core/storage/firebaseAdapter");
            Adapter.setDefaultClass(FirebaseAdapter);

            Office.initialize = () => { };
            Office.onReady(() => setReady(true));
        }

        fetchData();
    }, []);

    if (!isReady) {
        return <div></div>;
    }

    return (
        <FirebaseAuthProvider>
            <AuthConsumer>
                {() => children}
            </AuthConsumer>
        </FirebaseAuthProvider>
    );
}

export default PPTAddinAppInitializer;
