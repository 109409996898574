import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import firebase from "firebase/compat/app";

import getLogger, { LogGroup } from "js/core/logger";
import { LoadingPage } from "js/react/components/LoadingPage";
import sanitizeRelativeUrl from "common/utils/sanitizeRelativeUrl";
import { RemoveSplashScreen } from "js/core/SplashScreen";
import ErrorMessage from "js/app/ErrorMessage";
import { auth } from "js/firebase/auth";

const logger = getLogger(LogGroup.AUTH);

export function Logout() {
    const [status, setStatus] = useState<"pending" | "succeeded" | "failed">("pending");
    const unsubscribeRef = useRef<() => void>();

    const handleAuthStateChanged = (user: firebase.User | null) => {
        unsubscribeRef.current?.();
        unsubscribeRef.current = undefined;

        (async () => {
            try {
                if (user) {
                    logger.info("[Logout] User is logged in, logging out..");
                    // We'll sign out from all fb apps
                    for (const app of firebase.apps) {
                        await auth(app).signOut();
                    }
                }

                logger.info("[Logout] User is logged out..");

                Cookies.set("logged-in", "false");

                const urlParams = new URLSearchParams(window.location.search);
                if (urlParams.has("stay")) {
                    logger.info("[Logout] Staying on the page..");
                    setStatus("succeeded");
                    return;
                }

                const continueUrl = sanitizeRelativeUrl(urlParams.get("continue") ?? "/");
                logger.info(`[Logout] Redirecting to ${continueUrl}..`);
                window.location.href = continueUrl;
            } catch (err) {
                logger.error(err, "[Logout] Error signing out");
                setStatus("failed");
            }
        })();
    };

    const handleAuthError = (error: firebase.auth.Error) => {
        unsubscribeRef.current?.();
        unsubscribeRef.current = undefined;

        logger.error(new Error(error.message), "[Logout] Firebase auth error", error);
        setStatus("failed");
    };

    useEffect(() => {
        RemoveSplashScreen();

        logger.info("[Logout] Subscribing to auth state changes");
        unsubscribeRef.current = auth().onAuthStateChanged(handleAuthStateChanged, handleAuthError);

        return () => {
            unsubscribeRef.current?.();
        };
    }, []);

    if (status === "pending") {
        return <LoadingPage />;
    }

    if (status === "succeeded") {
        return <div id="success-message">Logged out</div>;
    }

    return <ErrorMessage message={"Sorry, we could not log you out."} />;
}
