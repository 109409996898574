import loadable from "@loadable/component";
import React from "react";
import { Route, Router, Switch } from "react-router-dom";

import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";

import { Logout } from "client-app/Logout";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { browserHistory } from "js/react/history";
import theme from "js/react/materialThemeOverrides";

import PPTAddinAppInitializer from "./PPTAddinAppInitializer";
import PPTAddinAuthenticate from "./PPTAddinAuthenticate";
import PPTAddinCheckout from "./PPTAddinCheckout";
import PPTAddinChoosePlan from "./PPTAddinChoosePlan";
import PPTAddinCommands from "./PPTAddinCommands";
import PPTAddinVideo from "./PPTAddinVideo";

import LegacyPPTAddinAppInitializer from "legacy-js/react/views/PPTAddin/PPTAddinAppInitializer";

// IMPORTANT: if you're making changes to any of the asynchronous imports below, make sure you test
// them locally with the string-replace-loader loader disabled in webpack.dev.config.js

const LoadablePPTAddinTaskpane = loadable(() => import(/* webpackChunkName: "pptAddinTaskpane" */ "js/react/views/PPTAddin/PPTAddinTaskpane"), {
    fallback: <FetchingClickShield backgroundColor="#000000" visible />
});

const LoadablePPTAddinEditor = loadable(() => import(/* webpackChunkName: "pptAddinEditor" */ "js/react/views/PPTAddin/PPTAddinEditor"), {
    fallback: <FetchingClickShield opaque visible />
});

const LoadableLegacyPPTAddinEditor = loadable(() => import(/* webpackChunkName: "legacy-pptAddinEditor" */ "legacy-js/react/views/PPTAddin/PPTAddinEditor"), {
    fallback: <FetchingClickShield opaque visible />
});

const WithTheme = ({ children }) => (
    <MuiThemeProvider theme={theme}>
        {children}
    </MuiThemeProvider>
);

function PPTAddin() {
    return (
        <Router history={browserHistory}>
            <Switch>
                <Route path="/ppt-addin/commands" render={() => <PPTAddinCommands />} />
                <Route path="/ppt-addin/video" render={() => <PPTAddinVideo />} />

                <Route path="/ppt-addin/authenticate/:page" render={props => (
                    <WithTheme>
                        <PPTAddinAppInitializer>
                            <PPTAddinAuthenticate {...props} />
                        </PPTAddinAppInitializer>
                    </WithTheme>
                )} />
                <Route path="/ppt-addin/logout" render={() => (
                    <PPTAddinAppInitializer>
                        <Logout />
                    </PPTAddinAppInitializer>
                )} />
                <Route path="/ppt-addin/choosePlan" render={() => (
                    <WithTheme>
                        <PPTAddinAppInitializer>
                            <PPTAddinChoosePlan />
                        </PPTAddinAppInitializer>
                    </WithTheme>
                )} />
                {/* Heavy views are loaded asynchronously */}
                <Route path="/ppt-addin/checkout" render={() => (
                    <WithTheme>
                        <PPTAddinAppInitializer>
                            <PPTAddinCheckout />
                        </PPTAddinAppInitializer>
                    </WithTheme>
                )} />
                <Route path="/ppt-addin/taskpane" render={() => (
                    <WithTheme>
                        <PPTAddinAppInitializer>
                            <LoadablePPTAddinTaskpane />
                        </PPTAddinAppInitializer>
                    </WithTheme>
                )} />
                <Route path="/ppt-addin/editor" render={() => (
                    <PPTAddinAppInitializer>
                        <LoadablePPTAddinEditor />
                    </PPTAddinAppInitializer>
                )} />
                {/* Legacy editor */}
                <Route path="/ppt-addin/legacy-editor" render={() => (
                    <WithTheme>
                        <LegacyPPTAddinAppInitializer>
                            <LoadableLegacyPPTAddinEditor />
                        </LegacyPPTAddinAppInitializer>
                    </WithTheme>
                )} />
            </Switch>
        </Router>
    );
}

export default PPTAddin;
